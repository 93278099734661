import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import ApiManager from '../../../../ApiManager';

class Login extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      password: "",
      repeatPassword: "",
      error: "",
      showPassword: false,
      succes: false,
      redirect: null,
      loading: false,
    };
  }

  changePassword = (event) => {
    event.preventDefault();

    this.setState({ loading: true }, () => {
      let password = this.state.password;
      let repeatPassword = this.state.repeatPassword;

      if (password === '' || repeatPassword === '') {
        return;
      }
      else if (password !== repeatPassword) {
        this.setState({ error: 'Passwords are not the same', loading: false });
        return;
      }

      let body = {
        newPassword: password,
      };

      ApiManager.post('/settings/account/changePassword', body, this.props.user)
        .then(data => {
          if (data === 'OK') {
            this.setState({ succes: true })
          }
          else {
            console.log(data);
          }
        })
        .catch(error => {
          this.setState({ error: error.message ? error.message : error.status, loading: false })
        });
    })
  }

  onInputChange = name => event => {
    let stateObj = { error: "" };
    stateObj[name] = event.target.value;

    this.setState(stateObj);
  }

  onEnter = (event) => {
    if (event.keyCode === 13) {
      this.changePassword(event);
    }
  }

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    if (!this.props.user) {
      return (
        <Redirect to='/login'></Redirect>
      )
    }

    if (this.state.redirect) {
      return (
        <Redirect push to={this.state.redirect}></Redirect>
      )
    }

    let error = this.state.error.length > 0;

    return (
      <Paper className="account loginPaper" elevation={12}>
        <Link to='/account' className='headerLink'>
          <div className='header'>
            <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="logo" />
            <p className='account-title'>
              Change password of Ellipsis Account: <b>{this.props.user.username}</b>
            </p>
          </div>
        </Link>
        <div className="content">
          {!this.state.succes ?
            <form onSubmit={this.changePassword}>
              <TextField
                required
                type={this.state.showPassword ? 'text' : 'password'}
                label="New Password"
                fullWidth
                onChange={this.onInputChange('password')}
                error={error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                name='password'
                autoComplete='current-password'
                autoFocus
              />
              <TextField
                required
                type={this.state.showPassword ? 'text' : 'password'}
                label="Repeat New Password"
                fullWidth
                onChange={this.onInputChange('repeatPassword')}
                error={error}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={this.state.error}
                autoComplete='new-password'
                name='new-password'
              />
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                onKeyUp={this.onEnter}
                disabled={this.state.loading}
              >
                {this.state.loading ? <CircularProgress /> : null}Change Password
            </Button>
              <Button
                fullWidth
                color="inherit"
                variant="contained"
                onClick={() => { this.setState({ redirect: '/account/credentials' }) }}
                className="cancelButton"
              >
                Back
              </Button>
            </form> :
            <Redirect push to={{ pathname: '/account', state: { message: 'Password has been changed' } }} />}
        </div>
      </Paper>
    );
  }
}

export default Login;
