import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import ApiManager from '../../../../ApiManager';
import { Typography } from '@material-ui/core';


function GetError(props) {
  const error = props.error;
  if (error) {
    return (<Typography
      color='error'
      variant='body1'
    >{error}
    </Typography>
    );
  } else {
    return (<Typography></Typography>);
  }
}

class ChangeEmail extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      repeatEmail: "",
      error: "",
      loading: false,
      succes: false,
      redirect: null,
    };
  }

  changeEmail = (event) => {
    event.preventDefault();

    this.setState({ loading: true }, () => {
      let email = this.state.email;
      let repeatEmail = this.state.repeatEmail;

      if (email === '' || repeatEmail === '') {
        return;
      }
      else if (email !== repeatEmail) {
        this.setState({ error: 'Emailaddresses are not the same', loading: false });
        return;
      }

      let body = {
        newEmail: email,
      };

      ApiManager.post('/settings/account/changeEmail', body, this.props.user)
        .then(data => {
          if (data === 'OK') {
            this.setState({ succes: true })
          }
          else {
            console.log(data);
          }
        })
        .catch(error => {
          this.setState({ error: error.message ? error.message : error.status, loading: false })
        });
    });
  }

  onInputChange = name => event => {
    let stateObj = { error: "" };
    stateObj[name] = event.target.value;

    this.setState(stateObj);
  }

  onEnter = (event) => {
    if (event.keyCode === 13) {
      this.changeEmail(event);
    }
  }

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  redirect = () => {
    this.setState({ redirect: true });
  }

  render() {
    if (!this.props.user) {
      return (
        <Redirect to='/login'></Redirect>
      )
    }

    if (this.state.redirect) {
      return (
        <Redirect push to={this.state.redirect}></Redirect>
      )
    }

    let error = this.state.error.length > 0;

    return (
      <Paper className="account loginPaper" elevation={12}>
        <Link to='/account' className='headerLink'>
          <div className='header'>
            <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="logo" />
            <p className='account-title'>
              Change email of Ellipsis Account: <b>{this.props.user.username}</b>
            </p>
          </div>
        </Link>
        <div className="content">
          {!this.state.succes ?

            <form onSubmit={this.changeEmail}>
              <GetError error={this.state.error}></GetError>
              <TextField
                required
                type='email'
                label='New Email Address'
                fullWidth
                onChange={this.onInputChange('email')}
                error={error}
                helperText={error}
                autoComplete="email"
                autoFocus
              />
              <TextField
                required
                type='email'
                label='Repeat New Email Address'
                fullWidth
                onChange={this.onInputChange('repeatEmail')}
                error={error}
                helperText={error}
                autoComplete="email"
              />
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                onKeyUp={this.onEnter}
                disabled={this.state.loading}
              >
                {this.state.loading ? <CircularProgress /> : null} Change email
            </Button>
              <Button
                fullWidth
                color="inherit"
                variant="contained"
                onClick={() => { this.setState({ redirect: '/account/credentials' }) }}
                className="cancelButton"
              >
                Back
              </Button>
            </form> :
            <Redirect push to={{ pathname: '/account', state: { message: 'Confirm your new email address by clicking the link we just sent you' } }} />
          }
        </div>
      </Paper>
    );
  }
}

export default ChangeEmail;
