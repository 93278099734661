import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import AvatarEditor from 'react-avatar-editor'
import { DropzoneArea } from 'material-ui-dropzone'

import ApiManager from '../../../../ApiManager';

import './ChangeAvatar.css';

class ChangeAvatar extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      step: 0,
      file: null,
      editedFile: null,
      gottenImage: null,
    };
  }

  redirect = (value) => {
    this.setState({ redirect: value });
  }

  setFile = (file) => {
    this.setState({file: file}, this.nextStep)
  }

  setEditedFile = (file) => {
    this.setState({editedFile: file}, this.nextStep)
  }

  setGottenImage = (file) => {
    this.setState({gottenImage: file}, this.nextStep)
  }

  nextStep = () => {
    if(this.state.step === 2)
    {
      this.props.onImageChange(this.state.gottenImage);
      this.setState({redirect: '/account'})
    }
    else {
      this.setState({step: this.state.step + 1})
    }
  }

  prevStep = () => {
    this.setState({step: this.state.step - 1})
  }

  render() {
    if (!this.props.user) {
      return (
        <Redirect to='/login'></Redirect>
      )
    }

    if (this.state.redirect) {
      return (
        <Redirect push to={this.state.redirect}></Redirect>
      )
    }

    return (<Paper className="account avatarPaper" elevation={12}>
      <Link to='/account' className='headerLink'>
        <div className='header'>
          <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="logo" />
          <p className='account-title'>
            Upload an avatar for your Ellipsis Account: <b>{this.props.user.username}</b>
          </p>
        </div>
      </Link>
      <div className="content">
        {
          this.state.step === 0
          ? <Choose
              redirect={this.redirect}
              nextStep={this.nextStep}
              setFile={this.setFile}
            />
          : null
        }
        {
          this.state.step === 1
          ? <Edit
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              file={this.state.file}
              setEditedFile={this.setEditedFile}
            />
          : null
        }
        {
          this.state.step === 2
          ? <Upload
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              edited={this.state.editedFile}
              user={this.props.user}
              setGottenImage={this.setGottenImage}
            />
          : null
        }
      </div>
    </Paper>);
  }
}

export default ChangeAvatar;

class Choose extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      file: null,
    };
  }

  nextStep = () => {
    this.props.setFile(this.state.file);
  }

  onFileChange = (files) => {
    if (files.length > 0)
    {
      this.setState({file: files[0]})
    }
    else
    {
      this.setState({files: null});
    }
  }

  render = () => {
    return (<Container>
      <DropzoneArea
        dropzoneClass='avatarDropzone'
        acceptedFiles={['image/*']}
        dropzoneText={"Drag and drop an image here or click"}
        onChange={this.onFileChange}
        filesLimit={1}
        showPreviews={true}
        showPreviewsInDropzone={false}
        maxFileSize={15000000}
      />
      <div className='buttonContainer'>
        <Button
          className='prev'
          fullWidth
          color="inherit"
          variant="contained"
          onClick={() => this.props.redirect('/account/profile')}
        >
          Back
        </Button>
        <Button
          className='next'
          fullWidth
          color="primary"
          variant="contained"
          disabled={this.state.file ? false : true}
          onClick={this.nextStep}
        >
          Next
        </Button>
      </div>
    </Container>)
  }
}

class Edit extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.initSize = 250;
    this.maxSize = 250;

    this.state = {
      file: null,
      scale: 1,
      rotate: 0,
      color: [255, 255, 255, 0.75],
      size: this.initSize,
    };

    this.editor = React.createRef();
  }

  setScale = (e, value) => {
    this.setState({scale: value});
  }

  setRotate = (e, value) => {
    this.setState({rotate: value});
  }

  prepare = () => {
    this.setState({color: [0,0,0,0]}, () => {
      this.props.setEditedFile(this.editor.current.canvas.toDataURL("image/jpeg"))
    })
  }

  render = () => {
    return (<Container>
      <AvatarEditor
        ref={this.editor}
        image={this.props.file}
        color={this.state.color}
        scale={this.state.scale}
        rotate={this.state.rotate}
        borderRadius={this.state.size}
        width={this.state.size}
        height={this.state.size}
        border={0}
      />
      <Grid container spacing={3}>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Typography paragraph>Zoom</Typography>
          </Grid>
          <Grid item xs={9}>
            <Slider
              defaultValue={1}
              valueLabelDisplay="auto"
              step={0.1}
              min={0.25}
              max={10}
              onChange={this.setScale}
              marks={[
                {value: 0.25, label: '0.25'},
                {value: 10, label: '10'},
              ]}
              valueLabelFormat={x => `${x}x`}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={3}>
            <Typography paragraph>Rotation</Typography>
          </Grid>
          <Grid item xs={9}>
            <Slider
              defaultValue={0}
              valueLabelDisplay="auto"
              step={1}
              min={0}
              max={360}
              onChange={this.setRotate}
              marks={[
                {value: 0, label: '0'},
                {value: 180, label: '180'},
                {value: 360, label: '360'},
              ]}
              valueLabelFormat={x => `${x}°`}
            />
          </Grid>
        </Grid>
      </Grid>
      <div className='buttonContainer'>
        <Button
          className='prev'
          fullWidth
          color="inherit"
          variant="contained"
          onClick={this.props.prevStep}
        >
          Back
        </Button>
        <Button
          className='next'
          fullWidth
          color="primary"
          variant="contained"
          onClick={this.prepare}
        >
          Use
        </Button>
      </div>
    </Container>)
  }
}

class Upload extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      gottenImage: null,
      loading: true
    };
  }

  componentDidMount = () => {
    this.upload();
  }


  getImage = () => {
    let body = {
      username: this.props.user.username
    };

    ApiManager.post('/settings/account/getProfile', body, this.props.user)
    .then(result => {
      this.setState({gottenImage: 'data:image/jpeg;base64,' + result.picture})
    })
    .catch(error => {
      console.error(error);
    });
  }

  upload = () => {
    let body = {
      picture: this.props.edited,
    };

    ApiManager.post('/settings/account/editProfile', body, this.props.user)
    .then(result => {
      if(result === 'OK')
      {
        this.setState({loading: false}, this.getImage)
      }
      else
      {
        console.warn(result)
      }
    })
    .catch(error => {
      console.error(error);
    });
  }

  setGottenImage = () => {
    this.props.setGottenImage(this.state.gottenImage);
  }

  render = () => {
    let loading = <React.Fragment>
      <Typography variant='h1' component='h1'>Uploading your image</Typography>
      <CircularProgress />
    </React.Fragment>

    return (<Container>
      {
        this.state.loading
        ? loading
        : (
            this.state.gottenImage
            ? <React.Fragment>
                <Typography variant='h1' component='h1'>Upload complete</Typography>
                <img className='gottenImage' src={this.state.gottenImage} alt={`Avatar of ${this.props.user.username}`} />
              </React.Fragment>
            : loading
        )
      }
      <div className='buttonContainer'>
        <Button
          className='prev'
          color="inherit"
          variant="contained"
          onClick={this.props.prevStep}
          disabled={this.state.loading}
        >
          Back
        </Button>
        <Button
          className='next'
          color="primary"
          variant="contained"
          onClick={this.setGottenImage}
          disabled={this.state.loading}
        >
          Finish
        </Button>
      </div>
    </Container>)
  }
}
