import React, { PureComponent } from 'react';
import { Redirect, NavLink } from 'react-router-dom';

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './Login.css';

import ApiManager from '../../ApiManager';

class Login extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      username: "",
      password: "",
      error: "",
      showPassword: false,
    };
  }



  login = async (event) => {
    event.preventDefault();
    let username = this.state.username.trim(); //to shave off leading (and trailing) spaces we trim()
    let password = this.state.password.trim();

    if (username === '' || password === '') {
      this.setState({ error: 'username and password are required' })
      return;
    }

    username = username.toLowerCase();

    let body = {
      username: username,
      password: password
    };

    let user = await ApiManager.post('/account/login', body)
    .then(data => {
      let token = data.token;
      user = {
        username: username,
        token: token,
      };

      return ApiManager.get('/account/info', null, user);
    })
    .then((result) => {
      user.username = result.username;
      user.representatives =  result.representatives
      user.commercial = result.commercial
      user.representing = result.representing
      return user;
    })
    .catch(error => {
      console.error(error)
      this.setState({ error: error.message ? error.message : error.status })
    })

    if(!this.state.error)
    {
      ApiManager.post('/settings/account/getProfile', {username: user.username}, user)
      .then((result) => {
          user.about = result.about
          user.picture = 'data:image/jpeg;base64,' + result.picture
          this.props.onLogin(user);
      })
      .catch(error => {
        if (error.status === 400 && error.message.includes('Given user does not have a profile picture.'))
        {
          this.props.onLogin(user);
        }
        else
        {
          console.error(error)
        }
      })
    }
  }

  onInputChange = name => event => {
    let stateObj = { error: "" };
    stateObj[name] = event.target.value;
    this.setState(stateObj);
  }

  onEnter = (event) => {
    if (event.keyCode === 13) {
      this.login(event);
    }
  }

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {
    if (this.props.user) {
      return (
        <Redirect to='/account'></Redirect>
      )
    }

    let error = this.state.error.length > 0;

    return (
      <Paper className="account loginPaper" elevation={12}>
        <div className='header'>
          <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="logo" />
          <p className='account-title'>
            Login with your Ellipsis Account
					</p>
        </div>
        <div className="content">
          <form onSubmit={this.login}>
            <TextField
              required
              label='Username or email'
              fullWidth
              onChange={this.onInputChange('username')}
              error={error}
            />
            <TextField
              required
              type={this.state.showPassword ? 'text' : 'password'}
              label="Password"
              fullWidth
              onChange={this.onInputChange('password')}
              error={error}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={this.state.error}
            />
            <Button
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
              onKeyUp={this.onEnter}
            >
              Log in
						</Button>
          </form>
          <div className='links'>
            <NavLink to='/account/register'>Sign up</NavLink>
            <NavLink to='/account/resetPassword'>Forgot your password?</NavLink>
          </div>
        </div>
      </Paper>
    );
  }
}

export default Login;
