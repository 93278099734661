import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";

import MyAccount from './MyAccount/MyAccount';
import MyProfile from './MyAccount/MyProfile';
import MyCredentials from './MyAccount/MyCredentials';
import Representatives from './MyAccount/Representatives';

import ChangeAvatar from './MyAccount/ChangeAvatar/ChangeAvatar';
import ChangePassword from './MyAccount/ChangePassword/ChangePassword';
import ChangeEmail from './MyAccount/ChangeEmail/ChangeEmail';
import AboutMe from './MyAccount/AboutMe/AboutMe';

import ResetPassword from './ResetPassword/ResetPassword';
import Register from './Register/Register';

import "./Account.css";

export class Account extends Component {
	constructor (props, context) {
		super(props, context)

		this.state = {
			maps: null,
			adminMaps: [],
			Redirect: null,
		}
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.user !== prevProps.user)
		{
			if (!this.props.user)
			{
				this.setState({Redirect: <Redirect to='/login'></Redirect>})
			}
		}
	}

	render() {
		if (this.state.Redirect) {
			return this.state.Redirect
    }

		return (
			<div className='accountContainer'>
				<Route
					exact
					path="/account"
					render={() =>
						<MyAccount
							user={this.props.user}
							onLogout={this.props.onLogout}
						/>
					}
				/>
				<Route
					exact
					path="/account/representatives"
					render={() =>
						<Representatives
							user={this.props.user}
							setRepresentatives={this.props.setRepresentatives}
						/>
					}
				/>
				<Route
					exact
					path="/account/changeAvatar"
					render={() =>
						<ChangeAvatar
							user={this.props.user}
							onImageChange={this.props.onImageChange}
						/>
					}
				/>
				<Route
					exact
					path="/account/changeAbout"
					render={() =>
						<AboutMe
							user={this.props.user}
							onAboutChange={this.props.onAboutChange}
						/>
					}
				/>
				<Route
					exact
					path="/account/profile"
					render={() =>
						<MyProfile
							user={this.props.user}
						/>
					}
				/>
				<Route
					exact
					path="/account/credentials"
					render={() =>
						<MyCredentials
							user={this.props.user}
						/>
					}
				/>
				<Route
					exact
					path="/account/changePassword"
					render={() =>
						<ChangePassword
							user={this.props.user}
						/>
					}
				/>
				<Route
					exact
					path="/account/changeEmail"
					render={() =>
						<ChangeEmail
							user={this.props.user}
						/>
					}
				/>
				<Route
					exact
					path="/account/register"
					render={() =>
						<Register
							user={this.props.user}
						/>
					}
				/>
				<Route
					exact
					path="/account/resetPassword"
					render={() =>
						<ResetPassword
							user={this.props.user}
						/>
					}
				/>
			</div>
		)
	}
}

export default Account;
