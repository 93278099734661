import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import ApiManager from '../../../ApiManager';
import { Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';

function GetError(props) {
  const error = props.error;
  if (error) {
    return (<Typography
      color='error'
      variant='body1'
    >{error}
    </Typography>
    );
  } else {
    return (<Typography></Typography>);
  }
}

class Representatives extends PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      representative:'',
      error:'',
      search:'',
      filteredRepresentatives:[]
    };
  }

  componentDidMount(){
    if(this.props.user){
      this.setState({filteredRepresentatives:this.props.user.representatives})
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.user && prevProps.user != this.props.user){
      this.setState({filteredRepresentatives:this.props.user.representatives, search:''})
    }
  }

  addRepresentative = ()=>{

    let body = {user:this.props.user.username, representative:this.state.representative, remove:false}
    ApiManager.post('/settings/account/representative', body, this.props.user).then(()=>{
      let representatives = this.props.user.representatives
      representatives.unshift(this.state.representative)
      this.props.setRepresentatives(representatives)
      this.setState({representative:'', error:''})
    }).catch((e)=>{
      if(this.props.user.username === this.state.representative){
        this.setState({error:'Cannot add yourself as representative'})
      }else{
        this.setState({error:'user does not exsist'})
      }
    })


  }

  removeRepresentative = (representative)=>{
    let body = {user:this.props.user.username, representative:representative, remove:true}
    ApiManager.post('/settings/account/representative', body, this.props.user)

    let representatives = [...this.props.user.representatives]
    representatives = representatives.filter((r)=>r!=representative)
    this.props.setRepresentatives(representatives)

  }

  onSearch = (e)=>{
      this.setState({search:e.target.value})

      if(e.target.value != ''){
        let filteredRepresentatives = [...this.state.filteredRepresentatives]
        filteredRepresentatives = filteredRepresentatives.filter((representative)=>representative.includes(this.state.search))
        this.setState({filteredRepresentatives:filteredRepresentatives})
      }
      else{
        this.setState({filteredRepresentatives:this.props.user.representatives})
      }
  }

  render() {

    if (!this.props.user) {
      return (
        <Redirect to='/login'></Redirect>
      )
    }

    if (this.state.redirect) {
      return (
        <Redirect push to={this.state.redirect}></Redirect>
      )
    }

    let representatives = this.state.filteredRepresentatives.map((representative)=>{
      return(
          <ListItem>
          <ListItemIcon> <AccountCircleIcon/> </ListItemIcon>
          <ListItemText
            primary={representative}
          />
            <ListItemSecondaryAction>
                <IconButton size='small' edge="end" onClick={()=>{this.removeRepresentative(representative)}}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </ListItemSecondaryAction>
        </ListItem>
      )
    })

    return (
      <Paper className="account loginPaper" elevation={12}>
        <Link to='/account' className='headerLink'>
          <div className='header'>
            <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="logo" />
            <p className='account-title'>
              Managing representetives for: <b>{this.props.user.username}</b>
            </p>
          </div>
        </Link>
        <div className="content">

            <form >
              <GetError error={this.state.error}></GetError>
              <TextField
                required
                label='username'
                fullWidth
                onChange={(e)=>this.setState({representative:e.target.value})}
                error={this.state.error}
                value={this.state.representative}
                autoFocus
              />

              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={this.addRepresentative}
              >
                Add Representative
            </Button>

            <List style={{maxHeight: '200px', overflow: 'auto'}}>
            {this.props.user.representatives.length > 4 ?
              <ListItem>
              <Tooltip title='Search'>
              <ListItemIcon> <SearchIcon/> </ListItemIcon>
              </Tooltip>
              <TextField value={this.state.search} onChange={this.onSearch}/>
              <Tooltip title='cancel'>
              <ListItemSecondaryAction>
                  <IconButton size='small' edge="end" onClick={()=>this.setState({search:'', filteredRepresentatives:this.props.user.representatives})} >
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                </ListItemSecondaryAction>
                </Tooltip>
            </ListItem> : null
          }
              {representatives}
            </List>
              <Button
                fullWidth
                color="inherit"
                variant="contained"
                onClick={() => { this.setState({ redirect: '/account' }) }}
                className="cancelButton"
              >
                Cancel
              </Button>
            </form>
        </div>
      </Paper>
    );
  }
}

export default Representatives;
