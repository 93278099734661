let primary = {
  "light": "#08C8C8",
  "main": "#089EC8",
  "dark": "#0D2E4C",
  "contrastText": "#fff"
};

let secondary = {
  "main": "#C75B1C",
  "dark": "#873E13",
  "contrastText": "#fff"
};

var theme =
{
	palette: {
	 "common": {
      "black": "#000",
      "white": "#fff"
    },
		"primary": primary,
    "secondary": secondary,
		/*action: {main:'#9CEBEB', contrastText: '#9CEBEB'},
    info: { main: '#f5f5f5' },
    error: { main: '#ff0000' }*/
	},
	typography: {
		htmlFontSize: 14,
		fontFamily: [
			'"Roboto Condensed"',
			'"Roboto"',
			'"-apple-system"',
			'"BlinkMacSystemFont"',
			'"Segoe UI"',
			'"Helvetica Neue"',
			'"Arial"',
			'"sans-serif"',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
			'"Exo 2"',
		].join(','),
		h1: {
			fontFamily: '"Exo 2", "Roboto Condensed", "Roboto", "Arial", "sans-serif"',
			fontWeight: 500,
			lineHeight: 1.25,
			textTransform: 'uppercase',
			fontSize: '2.5rem',
			color: primary.dark,
		},
		h2: {
			boxSizing: 'border-box',
			fontFamily: '"Exo 2", "Roboto Condensed", "Roboto", "Arial", "sans-serif"',
			fontWeight: 400,
			fontSize: '2.5rem',
			lineHeight: 1,
			textTransform: 'uppercase',
			display: 'block',
			background: primary.dark,
			padding: '1.5rem',
			paddingLeft: '5vw',
			paddingRight: '5vw',
			width: 'calc(100% + 120px)',
			transform: 'translateX(-60px)',
			color: 'white',
			marginBottom: '2rem',
			textAlign: 'center',
		},
		h3: {
			fontFamily: '"Exo 2", "Roboto Condensed", "Roboto", "Arial", "sans-serif"',
			fontWeight: 700,
			fontSize: '2rem',
			lineHeight: 1.25,
			textTransform: 'uppercase',
			color: primary.dark,
		},
		h4: {
			fontFamily: '"Roboto Condensed", "Roboto", "Arial", "sans-serif"',
			fontWeight: 400,
			fontSize: '1.5rem',
			lineHeight: 1.5,
			color: primary.dark,
		},
		h5: {
			fontFamily: '"Roboto Condensed", "Roboto", "Arial", "sans-serif"',
			fontWeight: 700,
			fontSize: '1.5rem',
			lineHeight: 1,
			color: primary.dark,
		},
		subtitle1: {
			fontFamily: '"Roboto Condensed", "Roboto", "Arial", "sans-serif"',
			fontWeight: 500,
			fontSize: '1.5rem',
			lineHeight: 1.5,
			textAlign: 'left'
		},
		subtitle2: {
			fontFamily: '"Roboto Condensed", "Roboto", "Arial", "sans-serif"',
			fontWeight: 300,
			fontSize: '1.5rem',
			lineHeight: 1.5,
			textAlign: 'left',
		},
		body1: {
			fontFamily: '"Roboto Condensed", "Roboto", "Arial", "sans-serif"',
			fontWeight: 400,
			fontSize: '1.1rem',
			lineHeight: 1.5,
			hyphens: 'auto',
			textAlign: 'justify',
		},
	},
}

export default theme;