const apiUrl = "https://api.ellipsis-drive.com/v1";
//const apiUrl = 'https://dev.api.ellipsis-earth.com/v2';
// const apiUrl = 'http://localhost:7553/v1';

const ApiManager = {
  adminUserName: "admin",

  apiUrl: apiUrl,

  accessLevels: {
    viewMap: 100,
    aggregatedData: 200,
    viewGeoMessages: 300,
    addGeoMessages: 400,
    addGeoMessageImage: 410,
    addPrivateGeoMessage: 420,
    addPolygons: 500,
    addPrivateCustomPolygons: 510,
    addRestrictedPolygons: 525,
    viewPrivateGeoMessages: 550,
    deleteGeomessages: 600,
    alterOrDeleteCustomPolygons: 700,
    forms: 750,
    customPolygonLayers: 800,
    userManagement: 900,
    owner: 1000,

    mapPublicLevelOne: 300, // viewGeoMessages
    mapPublicLevelTwo: 500, // addPolygons

    min: 0,
    max: 1000,
  },

  get: (url, body, user, throwError = true) => {
    return apiManagerFetch("GET", url, body, user, throwError);
  },

  post: (url, body, user, throwError = true) => {
    return apiManagerFetch("POST", url, body, user, throwError);
  },

  fetch: (method, url, body, user, throwError = true) => {
    return apiManagerFetch(method, url, body, user, throwError);
  },
};

function CustomError() {
  var temp = Error.apply(this, arguments[0]);
  temp.name = this.name = "error";
  this.message = temp.message = arguments[0].message;
  this.status = temp.status = arguments[0].status;

  if (Object.defineProperty) {
    Object.defineProperty(this, "stack", {
      get: function () {
        return temp.stack;
      },
      configurable: true,
    });
  } else {
    this.stack = temp.stack;
  }
}

CustomError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: CustomError,
    writable: true,
    configurable: true,
  },
});

async function apiManagerFetch(method, url, body, user, throwError) {
  url = `${apiUrl}${url}`;
  let headers = {};

  if (body) {
    headers["Content-Type"] = "application/json";
  }

  if (user) {
    headers["Authorization"] = `Bearer ${user.token}`;
  }

  let gottenResponse = null;
  let isText = false;
  let isJson = false;

  let options = {
    method: method,
    headers: headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  return await fetch(url, options)
    .then((response) => {
      gottenResponse = response;

      let contentType = response.headers.get("Content-Type");

      if (contentType) {
        isText = contentType.includes("text");
        isJson = contentType.includes("application/json");
      } else {
        isText = true;
      }

      if (isJson) {
        return response.json();
      } else if (isText) {
        return response.text();
      } else {
        return response.blob();
      }
    })
    .then((result) => {
      if (gottenResponse.status === 200) {
        return result;
      } else {
        if (!isText) {
          throw new CustomError({
            status: gottenResponse.status,
            message: result.message,
          });
        } else {
          throw new CustomError({
            status: gottenResponse.status,
            message: result,
          });
        }
      }
    })
    .catch((e) => {
      if (throwError) {
        throw e;
      } else {
        console.error(e);
      }
    });
}

export default ApiManager;
