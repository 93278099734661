import React, { PureComponent } from 'react';

import { Link, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import './Register.css';

import ApiManager from '../../../ApiManager';

class Register extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      username: "",
      password: "",
      repeatPassword: "",
      email: "",
      error: "",
      showPassword: false,
      redirect: null,
      success: false,
    };
  }

  register = (event) => {
    event.preventDefault();

    let username = this.state.username;
    let password = this.state.password;
    let repeatPassword = this.state.repeatPassword
    let email = this.state.email

    if (username === '' || password === '' || repeatPassword === '' || email === '') {
      return;
    }
    else if (password !== repeatPassword) {
      this.setState({ error: 'Passwords are not the same' });
      return;
    }

    username = username.toLowerCase();

    let body = {
      username: username,
      password: password,
      email: email
    };

    ApiManager.post('/settings/account/register', body)
      .then(data => {
        this.setState({ success: true })
      })
      .catch(error => {
        this.setState({ error: error.message ? error.message : error.status })
      });
  }

  onInputChange = name => event => {
    let stateObj = { error: "" };
    stateObj[name] = event.target.value;

    this.setState(stateObj);
  }

  onEnter = (event) => {
    if (event.keyCode === 13) {
      this.register(event);
    }
  }

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  }

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  render() {

    if (this.props.user) {
      return (<Redirect to='/account'></Redirect>)
    }

    if (this.state.redirect) {
      return (<Redirect to={this.state.redirect}></Redirect>)
    }

    let error = {};
    if (this.state.error.length > 0) {
      let message = this.state.error.toLowerCase();

      if (message.includes('username')) {
        if (message.includes('characters')) {
          error.username = 'Username should be atleast 4 characters long and can only contain letters, numbers and underscores.';
        }
        else {
          error.username = this.state.error;
        }
      }

      if (message.includes('password')) {
        if (message.includes('characters')) {
          error.password = 'Password should be atleast 7 characters long.';
        }
        else {
          error.password = this.state.error;
        }
      }

      if (message.includes('email')) {
        error.email = this.state.error
      }
    }

    return (
      <Paper className="account registerPaper" elevation={12}>
        <Link to='/account' className='headerLink'>
          <div className='header'>
            <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="Ellipsis Logo" />
            <p className='account-title'>
              Register your Ellipsis Account
            </p>
          </div>
        </Link>
        <div className="content" key={this.state.success}>
          {
            !this.state.success ? <form onSubmit={this.register}>
              <TextField
                required
                label='Username'
                fullWidth
                onChange={this.onInputChange('username')}
                error={error && error.username ? true : false}
                helperText={error && error.username ? error.username : ''}
                autoComplete="username"
                name="username"
              />
              <TextField
                required
                type={this.state.showPassword ? 'text' : 'password'}
                label="Password"
                fullWidth
                onChange={this.onInputChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={error && error.password ? true : false}
                autoComplete="current_password"
                name="password"
              />
              <TextField
                required
                type={this.state.showPassword ? 'text' : 'password'}
                label="Repeat Password"
                fullWidth
                onChange={this.onInputChange('repeatPassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={error && error.password ? true : false}
                helperText={error && error.password ? error.password : ''}
              />
              <TextField
                required
                type='email'
                label='Email'
                fullWidth
                onChange={this.onInputChange('email')}
                error={error && error.email ? true : false}
                helperText={error && error.email ? error.email : ''}
                autoComplete="new_password"
                name="new_password"
              />
              <Button
                fullWidth
                color="primary"
                variant="contained"
                type="submit"
                onKeyUp={this.onEnter}
              >
                Register
              </Button>
              <Button
                fullWidth
                color="inherit"
                variant="contained"
                onClick={() => { this.setState({ redirect: '/login' }) }}
                className="cancelButton"
              >
                Cancel
                </Button>
            </form>
              : [<p key='message'>Registration is successful, check your email and click on the link we sent to validate your account</p>, <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => { this.setState({ redirect: '/login' }) }}
                className="backButton"
                key='button'
              >
                Back to login
                </Button>]}
        </div>
      </Paper>
    );
  }
}

export default Register;
