import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Modal from 'react-modal';
import { withRouter } from 'react-router';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import ApiManager from './ApiManager';

import Login from './Components/Login/Login';
import Account from './Components/Account/Account';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import theme from './theme';

import './App.css';

const localStorageUserItem = 'user';

class App extends Component {
  topItemRef = null;
  bottomItemRef = null;

  constructor(props, context) {
    super(props, context)
    document.title = 'Account - Ellipsis Earth Intelligence';

    this.state = {
      init: false,
      user: null,
      userOrigin: null,
      representativeError:''
    };

    this.theme = createMuiTheme(theme);
  }

  componentDidMount() {
    Modal.setAppElement('body');
    window.addEventListener('message', this.receiveMessage, false);

    this.retrieveUser();
  }

  checkOrigin = (url) => {
    return true;
    // if (typeof url === 'string') {
    //   let split = url.split('.');

    //   if ((split[0].includes('https://') && (split[1] === 'ellipsis-earth' || split[1] === 'ellipsis-drive') &&
    //     split[2] === 'com') || (split[0].includes('https://ellipsis-earth' || split[0].includes('https://ellipsis-drive')) && split[1].split('/')[0] === 'com'))
    //   {
    //     return true;
    //   }
    // }
    // return false;
  }

  setRepresentatives =(representatives)=>{
    let user = {...this.state.user}
    user.representatives = representatives
    this.setState({user:user})
  }

  receiveMessage = (event) => {
    if (event.data && event.data.type === 'init')
    {
      if (this.checkOrigin(event.origin) || event.data.dev || event.origin.includes('localhost') || event.origin.includes('192.168'))
      {
        let stateObj = {userOrigin: event.origin};

        let cb = null;
        if (event.data.data){stateObj.user = event.data.data}

        if(this.state.user && !stateObj.user)
        {
          window.parent.postMessage({type: 'overlayClose'}, event.origin);
          cb = () => {
            window.parent.postMessage({type: 'login', data: this.state.user}, event.origin)
          }
        }

        this.setState(stateObj, cb);
      }
    }
    else if (event.data && event.data.type === 'home')
    {
      this.props.history.push('/account');
    }
    else if (event.data && event.data.type === 'register') {
      this.props.history.push('/account/register');
    }
    else if (event.data && event.data.type === 'login') {
      this.props.history.push('/login');
    }
  }

  retrieveUser = async () => {
    let user = null;
    let userJson = localStorage.getItem(localStorageUserItem);

    if (!userJson || !this.state.userOrigin) {
      this.setState({ init: true });
      return;
    }

    user = JSON.parse(userJson);

    ApiManager.get('/account/validateLogin', null, user)
      .then(() => {
        if (user.username) {
          user.username = user.username.toLowerCase();
        }

        this.setState({ user: user, init: true }, () => {
          if(this.state.userOrigin){window.parent.postMessage({type: 'login', data: user}, this.state.userOrigin)}
        });
      })
      .catch(() => {
        this.setState({ init: true });
        localStorage.removeItem(localStorageUserItem);
        window.parent.postMessage({type: 'logout'}, this.state.userOrigin);
      });
  }

  scrollToBottom = () => {
    this.bottomItemRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  onLogin = (user) => {
    localStorage.setItem(localStorageUserItem, JSON.stringify(user));

    if (this.state.userOrigin) {
      window.parent.postMessage({type: 'login', data: user}, this.state.userOrigin);
      window.parent.postMessage({type: 'overlayClose'}, this.state.userOrigin);
    }

    this.setState({ user: user }, () => {
      this.props.history.push('/account');
    });
  }

  onLogout = () => {
    localStorage.removeItem(localStorageUserItem);
    this.setState({ user: null }, () => {
      window.parent.postMessage({type: 'logout'}, this.state.userOrigin);
    });
  }

  onImageChange = (picture) => {
    window.parent.postMessage({type: 'updateImage', data: picture}, this.state.userOrigin);
  }

  onAboutChange = (about) => {
    let user = {...this.state.user}
    user.about = about
    this.setState({user:user})
    window.parent.postMessage({type: 'updateAbout', data: about}, this.state.userOrigin);
  }



  overlayClick = (e) => {
    let element = e.target;

    if (element.nodeName === 'path') {element = element.parentElement}
    if (element.nodeName === 'svg') {element = element.parentElement}
    if (element.nodeName === 'SPAN') {element = element.parentElement}

    let classNames = element.className;
    if (classNames === 'overlay' || classNames.includes('overlayClick'))
    {
      window.parent.postMessage({type: 'overlayClose'}, this.state.userOrigin);
    }
  }

  render() {
    // if (!this.state.init || !this.state.userOrigin)
    // {
    //   if (!window.location.href.includes('https://account.ellipsis-earth.com/') &&
    //     !window.location.href.includes('https://account.ellipsis-drive.com/') &&
    //     !window.location.href.includes('localhost') && !window.location.href.includes('192.168.'))
    //   {
    //     return null;
    //   }
    // }

    let contentClassName = 'content';

    return (
      <div className='App'>
        <ThemeProvider theme={this.theme}>
            <Typography variant="body1" component='div' className={contentClassName}>
              <Route
                exact
                path='/'
                render={() =>{ return this.props.user ?
                  <Account
                    user={this.state.user}
                    setRepresentatives={this.setRepresentatives}
                    onLogout={this.onLogout}
                    onImageChange={this.onImageChange}
                    onAboutChange={this.onAboutChange}
                  /> :
                  <Login
                    onLogin={this.onLogin}
                    user={this.state.user}
                  />
                }}
              />
              <Route
                exact
                path='/login'
                render={() =>
                  <Login
                    onLogin={this.onLogin}
                    user={this.state.user}
                  />
                }
              />
              <Route
                path='/account'
                render={() =>
                  <Account
                    user={this.state.user}
                    onLogout={this.onLogout}
                    onImageChange={this.onImageChange}
                    onAboutChange={this.onAboutChange}
                    setRepresentatives={this.setRepresentatives}
                  />
                }
              />
              <Route render={() => <Redirect to='/login'></Redirect>} />
              <div className='overlay' onClick={this.overlayClick}>
                <IconButton aria-label="close overlayClick" className='overlayClick'>
                  <CloseIcon fontSize="large" />
                </IconButton>
              </div>
            </Typography>
        </ThemeProvider>
      </div>
    );

  }

}

export default withRouter(App);
