import React, { PureComponent } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

class MyProfile extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      redirect: null,
      content: null,
    };
  }


  logout = () => {
    this.props.onLogout();
  }

  createContent = () => {
    let buttons = <div className="buttons">
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => this.setState({redirect:'/account/changeAvatar'})}
      >
        Avatar
      </Button>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={() => this.setState({redirect:'/account/changeAbout'})}
      >
        About me
      </Button>
      <Button
        fullWidth
        color="inherit"
        variant="contained"
        onClick={() => { this.setState({ redirect: '/account' }) }}
        className="cancelButton"
      >
        Cancel
      </Button>

    </div>;

    if (this.props.location.state && this.props.location.state.message)
    {
      this.setState({content: [<p>{this.props.location.state.message}</p>, <Button fullWidth variant="contained" color="primary" onClick={() => {this.props.location.state = null; this.setState({content: buttons})}}>Back</Button>]}, () => {setTimeout(() => {this.props.location.state = null; this.setState({content: buttons})}, 5000)})
    }
    else
    {
      this.setState({content: buttons});
    }
  }

  componentDidMount = () => {
    this.createContent();
  }

  render() {

    if (!this.props.user) {
      return (
        <Redirect to='/login'></Redirect>
      )
    }

    if (this.state.redirect) {
      return (
        <Redirect push to={this.state.redirect}></Redirect>
      )
    }


    return(<Paper className="account loginPaper" elevation={12}>
      <div className='header'>
        <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="logo"/>
        <p className='account-title'>
          Ellipsis Account Management
        </p>
      </div>
      <div className="content" key={this.props.adminMaps}>
        <Typography variant="h1">Welcome {this.props.user.username.charAt(0).toUpperCase() + this.props.user.username.slice(1)}</Typography>
        {this.state.content}
        <a
          href={`https://ellipsis-drive.com?token=${this.props.user.token}`}
          target='_blank'
          rel="noopener noreferrer"
          className='poweredLink'
        >
        Powered by <span>Ellipsis</span>
      </a>
      </div>
    </Paper>);
  }
}

export default withRouter(MyProfile);
