import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

import ApiManager from '../../../ApiManager';


function GetError(props) {
  const error = props.error;
  console.error(error);
  if (error) {
    return (<Typography
      color='error'
      variant='body1'
    >No user found with that email address
    </Typography>
    );
  } else {
    return (<Typography></Typography>);
  }
}

class Login extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: "",
      error: "",
      succes: false,
      redirect: false,
    };
  }

  reset = (event) => {
    event.preventDefault();
    let email = this.state.email;

    if (email === '') {
      return;
    }

    let body = {
      email: email
    };

    ApiManager.post('/settings/account/resetPassword', body)
      .then(data => {
        if (data === 'OK') {
          this.setState({ succes: true })
        }
        else {
          console.log(data)
        }
      })
      .catch(error => {
        this.setState({ error: error.message ? error.message : error.status })
      });
  }

  onInputChange = name => event => {
    let stateObj = { error: "" };
    stateObj[name] = event.target.value;

    this.setState(stateObj);
  }

  onEnter = (event) => {
    if (event.keyCode === 13) {
      this.login(event);
    }
  }

  handleMouseDownPassword = (e) => {
    e.preventDefault();
  }

  redirect = () => {
    this.setState({ redirect: true });
  }

  render() {
    if (this.props.user) {
      return (
        <Redirect push to='/account'></Redirect>
      )
    }

    if (this.state.redirect) {
      return (
        <Redirect to={this.state.redirect}></Redirect>
      )
    }

    let error = this.state.error.length > 0;

    return (
      <Paper className="account loginPaper" elevation={12}>
        <Link to='/account' className='headerLink'>
          <div className='header'>
            <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="logo" />
            <p className='account-title'>
              Reset your password of your Ellipsis Account
            </p>
          </div>
        </Link>
        <div className="content">
          {
            !this.state.succes ?
              <form onSubmit={this.reset}>
                <GetError error={this.state.error}></GetError>
                <TextField
                  required
                  label='Email'
                  fullWidth
                  onChange={this.onInputChange('email')}
                  error={error}
                  autoComplete="email"
                  name="email"
                />
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  onKeyUp={this.onEnter}
                >
                  Reset Password
              </Button>
                <Button
                  fullWidth
                  color="inherit"
                  variant="contained"
                  onClick={() => { this.setState({ redirect: '/login' }) }}
                  className="cancelButton"
                >
                  Cancel
              </Button>
              </form> :
              <div className="succes">
                <p>An email has been sent to you with instructions for a new password.</p>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={() => { this.setState({ redirect: '/login' })}}
                >
                  Back to login
              </Button>
              </div>
          }
        </div>
      </Paper>
    );
  }
}

export default Login;
