import React, { PureComponent } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import AvatarEditor from 'react-avatar-editor'
import { DropzoneArea } from 'material-ui-dropzone'

import ApiManager from '../../../../ApiManager';

import './AboutMe.css';

class AboutMe extends PureComponent {

  constructor(props, context) {
    super(props, context);

    this.state = {
      redirect:null,
      about:null,
      error:false,
      helperText:null
    };
  }

  componentDidMount(){
    if(this.props.user){
      this.setState({about:this.props.user.about})
    }
  }

  componentDidUpdate(prevProps){
    if(this.props.user.about != prevProps.user.about){
      this.setState({about:this.props.user.about})
    }
  }


  onSubmit = ()=>{
    let body = {about:this.state.about}
    ApiManager.post('/settings/account/editProfile', body, this.props.user).then(()=>{
      this.props.onAboutChange(this.state.about)
      this.setState({helperText: <p style={{'color':'green'}}> update successful </p>, error:false})
    }).catch((e)=>{
        this.setState({error:true, helperText: <p style={{'color':'red'}}> e.message </p> })
    })

  }

  render() {

    if (!this.props.user) {
      return (
        <Redirect to='/login'></Redirect>
      )
    }

    if (this.state.redirect) {
      return (
        <Redirect push to={this.state.redirect}></Redirect>
      )
    }


    return (
      <Paper className="account loginPaper" elevation={12}>
        <Link to='/account' className='headerLink'>
          <div className='header'>
            <img src="/images/logos/Ellipsis_Drive_Full_White.svg" alt="logo" />
            <p className='account-title'>
              Editing about for: <b>{this.props.user.username}</b>
            </p>
          </div>
        </Link>
        <div className="content">

            <form >

            <TextField
              required
              label="Edit about me"
              fullWidth
              multiline
              rows={8}
              helperText={this.state.helperText}
              error={this.state.error}
              type='text'
              onChange={e => { this.setState({about:e.target.value, error:false, helperText:null}) }}
              value={this.state.about}
              multiline
            />

              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={this.onSubmit}
              >
                Submit
            </Button>


              <Button
                fullWidth
                color="inherit"
                variant="contained"
                onClick={() => { this.setState({ redirect: '/account/profile' }) }}
                className="cancelButton"
              >
                Back
              </Button>
            </form>
        </div>
      </Paper>
    );
  }
}



export default AboutMe
